@import "https://fonts.googleapis.com/css2?family=Orbitron:wght@600&display=swap";

.Agents_Buttons {
  height: 100%;
  width: 100%;
  font-family: "Orbitron", sans-serif;
  background-size: cover;
  background-position: center center;
  background-image: url("imgs/buttonback.jpg");
  text-align: center;

  -webkit-font-smoothing: antialiased;
}
.Agents_Buttons #centered {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.Agents_Buttons span {
  margin: 0 10px;
}

.Agents_Buttons .btn-blue {
  background: #108fe8;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 -2px 0 3px #0d72b8 inset, 0 5px 5px rgba(3, 25, 41, 0.17),
    0 15px rgba(255, 255, 255, 0.25) inset;
  cursor: pointer;
  display: inline-block;
  height: 12vw;
  width: 12vw;
}
.Agents_Buttons .btn-blue:hover {
  background: #333;
  box-shadow: 0 -2px 0 3px #1a1a1a inset, 0 5px 5px rgba(0, 0, 0, 0.17),
    0 15px rgba(255, 255, 255, 0.32) inset;
}

.Agents_Buttons .btn-red {
  background: #e53030;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 -2px 0 3px #c91919 inset, 0 5px 5px rgba(65, 8, 8, 0.17),
    0 15px rgba(255, 255, 255, 0.25) inset;
  cursor: pointer;
  display: inline-block;
  height: 12vw;
  width: 12vw;
}
.Agents_Buttons .btn-red:hover {
  background: #333;
  box-shadow: 0 -2px 0 3px #1a1a1a inset, 0 5px 5px rgba(0, 0, 0, 0.17),
    0 15px rgba(255, 255, 255, 0.32) inset;
}

.Agents_Buttons .btn-green {
  background: #0ec518;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 -2px 0 3px #0b9512 inset, 0 5px 5px rgba(0, 7, 1, 0.17),
    0 15px rgba(255, 255, 255, 0.25) inset;
  cursor: pointer;
  display: inline-block;
  height: 12vw;
  width: 12vw;
}
.Agents_Buttons .btn-green:hover {
  background: #333;
  box-shadow: 0 -2px 0 3px #1a1a1a inset, 0 5px 5px rgba(0, 0, 0, 0.17),
    0 15px rgba(255, 255, 255, 0.32) inset;
}

.Agents_Buttons .btn-yellow {
  background: #ffc334;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 -2px 0 3px #ffb401 inset, 0 5px 5px rgba(103, 73, 0, 0.17),
    0 15px rgba(255, 255, 255, 0.25) inset;
  cursor: pointer;
  display: inline-block;
  height: 12vw;
  width: 12vw;
}
.Agents_Buttons .btn-yellow:hover {
  background: #333;
  box-shadow: 0 -2px 0 3px #1a1a1a inset, 0 5px 5px rgba(0, 0, 0, 0.17),
    0 15px rgba(255, 255, 255, 0.32) inset;
}
.Agents_Buttons .bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 8pt;
  margin: 15px;
}
.Agents_Buttons #time {
  color: white;
  font-size: 60pt;
}
