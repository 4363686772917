.Hero_Access {
  height: 100%;
  width: 100%;
}
.Hero_Access .login {
  background: #555555;
}
.Hero_Access .loginsubmit {
  position: relative;
  width: 100%;
  height: 4rem;
  margin: 3rem 0 2.2rem;
  color: rgba(255, 255, 255, 0.8);
  background: #777;
  font-size: 1.5rem;
  border-radius: 3rem;
  cursor: pointer;
  overflow: hidden;
  transition: width 0.3s 0.15s, font-size 0.1s 0.15s;
}
.Hero_Access {
  background: url("imgs/secret_service.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.Hero_Access ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fdfcfd;
  opacity: 1; /* Firefox */
}

.Hero_Access :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fdfcfd;
}

.Hero_Access ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fdfcfd;
}

.Hero_Access .demo {
  position: absolute;
  top: 70%;
  left: 50%;
  margin-left: -15rem;
  margin-top: -26.5rem;
  height: 30rem;
}
.Hero_Access .login__submit {
  background: #556677;
}
.Hero_Access .login__form {
  position: absolute;
  top: 0%;
  height: 100%;
}
.Hero_Access .login__submit {
  margin-top: 2rem;
}
.Hero_Access #error_message {
  color: white;
}
