.Boyard_Rooms {
  height: 100%;
  width: 100%;
  background: url("imgs/wood.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.Boyard_Door {
  height: 100%;
  width: 100%;
  background: url("imgs/oldsteel.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.Boyard_Rooms #plan {
  height: 100%;
  width: 100%;
  padding: 50px;
  background: url("imgs/plan.jpg") no-repeat center center fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.Boyard_Rooms #contact {
  position: absolute;
  margin-left: -130px;
  left: 50%;
  top: 30px;
  background: #f0730d;
  color: white;
  border-color: #f0730d;
}

.Boyard_Rooms #time {
  position: absolute;
  color: white;
  font-size: 40px;
  font-weight: 600;
  top: 0;
  background-color: #f0730d;
  padding: 7px;
  right: 30px;
}
