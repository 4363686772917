@import "https://fonts.googleapis.com/css?family=Oswald:400,700";
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css";

.Astronaut {
  background: #222;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.Astronaut .myCenter {
  position: absolute;
  text-align: center;
  margin: auto;
  height: 390px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.Astronaut .myLock {
  text-align: center;
  height: 310px;
  padding: 40px 50px 40px 50px;
  margin: auto;
  background: white;
  background: linear-gradient(white, #dcd3ca);
  border-radius: 25px;
  box-shadow: inset 0 -4px 4px #b1aaa1, 0 20px 40px rgba(0, 0, 0, 0.5),
    0 2px 5px 1px rgba(0, 0, 0, 0.7);
}
.Astronaut .myLock .lockInset {
  display: inline-block;
  position: relative;
  padding: 23px;
  background: linear-gradient(white, #dcd3ca);
  border-radius: 15px;
  box-shadow: inset 0 -8px 4px -4px #b1aaa1, 0 7px 10px rgba(0, 0, 0, 0.07),
    0 14px 10px rgba(0, 0, 0, 0.07), 0 20px 10px rgba(0, 0, 0, 0.07),
    0 27px 10px rgba(0, 0, 0, 0.07), 0 34px 10px rgba(0, 0, 0, 0.07),
    0 2px 3px 1px rgba(0, 0, 0, 0.5);
}
.Astronaut .myLock .lockLine {
  position: absolute;
  margin: auto -23px;
  width: 100%;
  top: 0px;
  bottom: 0px;
  height: 4px;
  background: linear-gradient(
    rgba(0, 0, 0, 0.2) 1px,
    transparent 1px,
    transparent 3px,
    rgba(0, 0, 0, 0.3) 3px
  );
}
.Astronaut .myLock .lockWrapper {
  text-align: center;
  position: relative;
  background: rgba(0, 0, 0, 0.85);
  background: linear-gradient(
    black 13%,
    #403c3a,
    black 75%,
    #110c14,
    black 85%
  );
  border-radius: 8px;
  height: 150px;
  box-shadow: inset 0 0 10px 2px black;
}
.Astronaut .myLock .dial {
  display: inline-block;
  vertical-align: top;
  height: 150px;
  width: 84px;
  padding-top: -50px;
  margin: 0px 7px;
  background: linear-gradient(
    #000000 0%,
    #000006 3%,
    #08000d 4%,
    #292527 7%,
    #55514c 9%,
    #847f76 11%,
    #979289 12%,
    #bab3a9 15%,
    #d9cfc6 17%,
    #f4eae1 19%,
    #fff4ec 20%,
    #fffffc 22%,
    #ffffff 24%,
    #fffffe 33%,
    #fffdf5 35%,
    #fef3eb 37%,
    #d4ccc2 45%,
    #ada69d 54%,
    #726d66 65%,
    #595550 71%,
    #4f4b47 74%,
    #4b4743 76%,
    #4b4743 79%,
    #4f4b47 80%,
    #6a665f 84%,
    #6d6861 85%,
    #6a665f 88%,
    #65605a 89%,
    #54504b 91%,
    #0d0711 98%,
    #02000a 99%,
    #000004 100%
  );
  border: 5px solid rgba(0, 0, 0, 0.25);
  border-top: none;
  border-bottom: none;
  border-left-image: linear-gradient(white, black);
  overflow: hidden;
  font-size: 45px;
  font-family: arial;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0 -1px 0 rgba(0, 0, 0, 0.5),
    0 -2px 0 rgba(255, 255, 255, 0.1);
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2), 0 8px 10px rgba(0, 0, 0, 0.2),
    0 13px 10px rgba(0, 0, 0, 0.2);
}
.Astronaut .myLock .dial ol {
  display: inline;
  position: relative;
  top: -50px;
}
.Astronaut .myLock .dial ol li {
  display: inline-block;
  position: relative;
  font-weight: 600;
  display: block;
  margin: 0 auto;
  height: 51px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.Astronaut .myLock .shadow {
  pointer-events: none;
  position: absolute;
  top: 0px;
  background: linear-gradient(
    black 5%,
    transparent 40%,
    transparent 60%,
    black 100%
  );
  border-radius: 8px;
  height: 150px;
  width: 100%;
}
.Astronaut .myLock .btnEnter {
  font-size: 30px;
  font-family: arial;
  font-weight: 600;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0 -1px 0 rgba(0, 0, 0, 0.5),
    0 -2px 0 rgba(255, 255, 255, 0.1);
  color: #333;
  cursor: pointer;
  margin: auto;
  width: 350px;
  height: 50px;
  margin-top: 15px;
  display: block;
  background: linear-gradient(white, #dcd3ca);
  border-radius: 15px;
  border: 1px solid #999;
  box-shadow: inset 0 -8px 4px -4px #b1aaa1, 0 7px 10px rgba(0, 0, 0, 0.07),
    0 14px 10px rgba(0, 0, 0, 0.07), 0 20px 10px rgba(0, 0, 0, 0.07),
    0 27px 10px rgba(0, 0, 0, 0.07), 0 34px 10px rgba(0, 0, 0, 0.07),
    0 2px 3px 1px rgba(0, 0, 0, 0.5);
}
.Astronaut .myLock .btnEnter:active {
  font-size: 26px;
  background: linear-gradient(white, #dcd3ca);
  border-radius: 15px;
  border: 1px solid #999;
  vertical-align: -5px;
  box-shadow: inset 0 7px 10px rgba(0, 0, 0, 0.07),
    inset 0 14px 10px rgba(0, 0, 0, 0.07), inset 0 20px 10px rgba(0, 0, 0, 0.07),
    inset 0 27px 10px rgba(0, 0, 0, 0.07), inset 0 34px 10px rgba(0, 0, 0, 0.07),
    inset 0 2px 3px 1px rgba(0, 0, 0, 0.5), inset 0 -8px 4px -4px #b1aaa1;
}
.Astronaut .sticker {
  float: left;
  position: relative;
  height: 150px;
  margin-top: 80px;
}
.Astronaut .sticker2 {
  float: right;
  position: relative;
  height: 150px;
  margin-top: 80px;
}
.Astronaut p {
  color: whitesmoke;
  text-align: center;
  padding: 20px;
  font-size: 39px;
  font-family: "Orbitron", sans-serif;
}
