@import "https://fonts.googleapis.com/css2?family=Anton&display=swap";

.Boyard_Alarm {
  background: url("imgs/alarmback.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #eee;
  text-align: center;
  font-family: "Anton", sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
}

.Boyard_Alarm .toggle {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 150px;
  background-color: #bbb;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
}

.Boyard_Alarm .toggle input {
  width: 100%;
  height: 100%;
  margin: 0 0;
  padding: 0 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.Boyard_Alarm .toggle label {
  display: block;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background-image: -webkit-linear-gradient(
    180deg,
    #fff 0%,
    #ddd 50%,
    #fff 50%,
    #eee 100%
  );
  background-image: -moz-linear-gradient(
    180deg,
    #fff 0%,
    #ddd 50%,
    #fff 50%,
    #eee 100%
  );
  background-image: -ms-linear-gradient(
    180deg,
    #fff 0%,
    #ddd 50%,
    #fff 50%,
    #eee 100%
  );
  background-image: -o-linear-gradient(
    180deg,
    #fff 0%,
    #ddd 50%,
    #fff 50%,
    #eee 100%
  );
  background-image: linear-gradient(
    180deg,
    #fff 0%,
    #ddd 50%,
    #fff 50%,
    #eee 100%
  );
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4), inset 0 -1px 1px #888,
    inset 0 -5px 1px #bbb, inset 0 -6px 0 white;
  -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4), inset 0 -1px 1px #888,
    inset 0 -5px 1px #bbb, inset 0 -6px 0 white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4), inset 0 -1px 1px #888,
    inset 0 -5px 1px #bbb, inset 0 -6px 0 white;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #666;
  text-shadow: 0 1px 0 white;
  cursor: text;
}

.Boyard_Alarm .toggle label:before {
  content: attr(data-off);
  position: absolute;
  top: 6px;
  color: green;
  font-size: 20px;
  right: 0;
  left: 0;
  z-index: 4;
}

.Boyard_Alarm .toggle label:after {
  content: attr(data-on);
  position: absolute;
  right: 0;
  bottom: 11px;
  left: 0;
  color: #999;
  font-size: 15px;

  text-shadow: 0 -1px 0 #eee;
}

.Boyard_Alarm .toggle input:checked + label {
  background-image: -webkit-linear-gradient(
    top,
    #eee 0%,
    #ccc 50%,
    #fff 50%,
    #eee 100%
  );
  background-image: -moz-linear-gradient(
    top,
    #eee 0%,
    #ccc 50%,
    #fff 50%,
    #eee 100%
  );
  background-image: -ms-linear-gradient(
    top,
    #eee 0%,
    #ccc 50%,
    #fff 50%,
    #eee 100%
  );
  background-image: -o-linear-gradient(
    top,
    #eee 0%,
    #ccc 50%,
    #fff 50%,
    #eee 100%
  );
  background-image: linear-gradient(
    top,
    #eee 0%,
    #ccc 50%,
    #fff 50%,
    #eee 100%
  );
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.4), inset 0 1px 7px -1px #ccc,
    inset 0 5px 1px #fafafa, inset 0 6px 0 white;
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.4), inset 0 1px 7px -1px #ccc,
    inset 0 5px 1px #fafafa, inset 0 6px 0 white;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4), inset 0 1px 7px -1px #ccc,
    inset 0 5px 1px #fafafa, inset 0 6px 0 white;
}

.Boyard_Alarm .toggle input:checked:hover + label {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), inset 0 1px 7px -1px #ccc,
    inset 0 5px 1px #fafafa, inset 0 6px 0 white;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), inset 0 1px 7px -1px #ccc,
    inset 0 5px 1px #fafafa, inset 0 6px 0 white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), inset 0 1px 7px -1px #ccc,
    inset 0 5px 1px #fafafa, inset 0 6px 0 white;
}

.Boyard_Alarm .toggle input:checked + label:before {
  z-index: 1;
  top: 11px;
  color: #999;
  font-size: 15px;
}

.Boyard_Alarm .toggle input:checked + label:after {
  bottom: 9px;
  color: green;
  text-shadow: none;
  font-size: 20px;
  z-index: 4;
}
.Boyard_Alarm p {
  font-size: 20px;
  margin: 10px;
}
.Boyard_Alarm #time {
  font-size: 80px;
  margin: 10px;
  margin-top: 0px;
  color: white;
  font-family: "Anton", sans-serif;
  -webkit-font-smoothing: antialiased;
}
.Boyard_Alarm #info {
  font-size: 40px;
  margin: 10px;
  margin-top: 0px;
  color: white;
  font-family: "Anton", sans-serif;
  -webkit-font-smoothing: antialiased;
}
.Boyard_Alarm #type {
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 0;
}
.Boyard_Alarm #overlay {
  position: absolute;
  background-color: black;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
}
.Boyard_Alarm #finger {
  position: absolute;
  background-color: black;
  left: 30%;
  width: 40%;
  z-index: 10;
  top: 30%;
}
