@import "https://fonts.googleapis.com/css?family=Roboto:500,700";
.Labo_Recept {
  background: url("imgs/laboback2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
  color: #435757;
  font: 500 16px "Roboto", sans-serif;
}

.Labo_Recept .container {
  max-width: 450px;
  margin: 0 auto;
  border-top: 5px solid #435757;
  background-color: rgba(164, 201, 157);
  box-shadow: 0 0 20px rgba(107, 133, 102);
  user-select: none;
}

.Labo_Recept h1 {
  margin: 0;
  padding: 20px;
  font-size: 1em;
  text-align: center;
}

.Labo_Recept .items {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 0;
  counter-reset: done-items undone-items;
}

.Labo_Recept h2 {
  position: relative;
  margin: 0;
  padding: 10px 0;
  font-size: 1.2em;
}

.Labo_Recept h2::before {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: -20px;
  width: 5px;
  background-color: #435757;
}

.Labo_Recept h2::after {
  display: block;
  float: right;
  font-weight: normal;
}

.Labo_Recept .done {
  order: 1;
  color: #435757;
}

.Labo_Recept .done::after {
  content: " (" counter(done-items) ")";
}

.Labo_Recept .undone {
  order: 3;
  color: #435757;
}

.Labo_Recept .undone::after {
  content: " (" counter(undone-items) ")";
}

/* hide inputs offscreen, but at the same vertical positions as the correpsonding labels, so that tabbing scrolls the viewport as expected */
.Labo_Recept .items input {
  display: block;
  height: 53px;
  margin: 0 0 -53px -9999px;
  order: 4;
  outline: none;
  counter-increment: undone-items;
}

.Labo_Recept input:checked {
  order: 2;
  counter-increment: done-items;
}

.Labo_Recept label {
  display: block;
  position: relative;
  padding: 15px 0 15px 45px;
  border-top: 1px dashed #fff;
  order: 4;
  cursor: pointer;
  animation: undone 0.5s;
}

.Labo_Recept label::before {
  content: "\f10c"; /* circle outline */
  display: block;
  position: absolute;
  top: 11px;
  left: 10px;
  font: 1.5em "FontAwesome";
}

.Labo_Recept label:hover,
input:focus + label {
  background-color: rgba(107, 133, 102);
}

.Labo_Recept input:checked + label {
  order: 2;
  animation: done 0.5s;
}

.Labo_Recept input:checked + label::before {
  content: "\f058"; /* circle checkmark */
}

@keyframes done {
  0% {
    opacity: 0;
    background-color: rgba(164, 201, 157);
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
    background-color: rgba(164, 201, 157);
  }
}

@keyframes undone {
  0% {
    opacity: 0;
    background-color: rgba(164, 201, 157);
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    background-color: rgba(164, 201, 157);
  }
}
.Labo_Recept #email {
  font-size: 25px;
}

.Labo_Recept #time {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-family: "Skranji", cursive;
  color: white;
  font-size: 60px;
  margin: 0;
}

.Labo_Recept #back {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 70px;
}
.Labo_Recept #sendbutton {
  margin: 10px;
  padding: 5px;
  font-size: 20px;
  color: white;
  background-color: #435757;
}
