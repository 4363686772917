@import "https://fonts.googleapis.com/css2?family=Skranji:wght@700&display=swap";

.Labo_Menu #left {
  height: 100%;
  width: 50%;
  background-color: #ebaccb;
  display: block;
  float: left;
}
.Labo_Menu #right {
  height: 100%;
  width: 50%;
  background-color: #a1d3a9;
  display: block;
  float: left;
}
.Labo_Menu .icon {
  width: 50%;
  margin-left: 25%;
  margin-top: 15%;
}
.Labo_Menu .title {
  font-family: "Skranji", cursive;
  color: white;
  text-align: center;
  font-size: 40px;
}
.Labo_Menu #time {
  font-family: "Skranji", cursive;
  color: white;
  font-size: 50px;
  text-align: center;
}
.Labo_Menu #timecontainer {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 300px;
  margin-left: -150px;
}

.Labo_Menu #time2 {
  font-family: "Skranji", cursive;
  color: black;
  font-size: 50px;
  text-align: center;
}
.Labo_Menu #timecontainer2 {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 300px;
  margin-left: -150px;
}
.Labo_Menu #image {
  width: 80%;
  margin-left: 10%;
  margin-top: 10%;
}

.Labo_Menu #back {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 70px;
}
