.Astronaut_Doors {
  margin: 0px;
  background-color: #e74c3c;
  padding: 0px;
}

.Astronaut_Doors .wrapper {
  height: 300px;
  width: 300px;
  margin: 50px auto;
  margin-top: 0;
  padding-top: 50px;
}

.Astronaut_Doors .base {
  background-color: #ecf0f1;
  width: 200px;
  height: 170px;
  border-radius: 30px;
  margin: 0 auto;
  position: relative;
  top: 100px;
  z-index: 100;
}

.Astronaut_Doors .base-bottom {
  background-color: #bdc3c7;
  width: 200px;
  height: 85px;
  border-radius: 0 0 30px 30px;
  top: 85px;
  position: relative;
  opacity: 0.4;
}

.Astronaut_Doors .lock-cirlce {
  height: 180px;
  width: 110px;
  border-radius: 45px;
  z-index: 10;
  background-color: #bdc3c7;
  position: relative;
  margin: 0 auto;
  bottom: 150px;
}

.Astronaut_Doors .lock-circle-inside {
  height: 180px;
  width: 50px;
  border-radius: 30px;
  z-index: 20;
  background-color: #e74c3c;
  position: relative;
  margin: 0 auto;
  top: 25px;
}

.Astronaut_Doors .lock-box {
  position: relative;
  height: 50px;
  width: 50px;
  background-color: #e74c3c;
  bottom: 230px;
  left: 170px;
  z-index: 80;
}

.Astronaut_Doors .lock-inside-top {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: black;
  z-index: 300;
  position: relative;
  bottom: 45px;
  left: 75px;
}

.Astronaut_Doors .lock-inside-bottom {
  width: 30px;
  height: 80px;
  background-color: black;
  z-index: 300;
  position: relative;
  bottom: 85px;
  left: 85px;
}
.Astronaut_Doors h1,
.Astronaut_Doors h2 {
  color: white;
  text-align: center;
  margin: 20px;
  font-size: 45px;
  font-family: "Orbitron", sans-serif;
}
.Astronaut_Doors h2 {
  font-size: 35px;
}
