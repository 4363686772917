@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap');

.MINI2_Mail {
  font-size: 18px;
  background-color: #9B6767;
  height: 100%;
}
.MINI2_Mail h1 {
  font-family: "Kalam", cursive;
  font-size: 70px;
  margin-bottom: 0;
}
.MINI2_Mail .writing {
  font-family: "Kalam", cursive;
  font-size: 30px;
  margin-bottom: 0;
}
.MINI2_Checklist {
  font-family: "Permanent Marker", cursive;
}

@keyframes flash {
  0% {
    background-image: url("imgs/led1.png");
  }
  50% {
    background-image: url("imgs/led2.png");
  }
  100% {
    background-image: url("imgs/led1.png");
  }
}

/* The element to apply the animation to */
.MINI2_Checklist .lights {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: flash 1s infinite;
}
