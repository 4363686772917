@import "https://fonts.googleapis.com/css?family=Lato:400,400italic,700";
@import "https://fonts.googleapis.com/css?family=Dosis:700";

.Astronaut_Phone {
  width: 100%;
  height: 100%;
  background-color: #2196f2;
  font-family: Lato;
  font-weight: 400;
  letter-spacing: 1px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Astronaut_Phone .pad {
  width: 400px;
  height: 587px;
  background-color: #232323;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  box-shadow: 0 30px 35px -25px black;
  animation: showPad 1s ease forwards 1;
}
.Astronaut_Phone .pad .dial-pad .contact {
  width: 60%;
  position: relative;
  margin-left: 20%;
  margin-top: 40px;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.Astronaut_Phone .pad .dial-pad .contact.showContact {
  opacity: 1;
}
.Astronaut_Phone .pad .dial-pad .contact .avatar {
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  box-shadow: 0 15px 30px -10px black;
  position: absolute;
  left: 0px;
  top: 8px;
}
.Astronaut_Phone .pad .dial-pad .contact .contact-info {
  border-radius: 8px;
  width: 85%;
  margin-left: 15%;
  background-color: #2d2d2d;
  height: 76px;
  overflow: hidden;
}
.Astronaut_Phone .pad .dial-pad .contact .contact-info > div {
  width: 80%;
  margin-left: 20%;
  font-size: 12px;
  margin-top: 3px;
}
.Astronaut_Phone .pad .dial-pad .contact .contact-info .contact-name {
  color: #fdfdfd;
  margin-top: 12px;
}
.Astronaut_Phone .pad .dial-pad .contact .contact-info .contact-position {
  font-style: italic;
  color: #aeaeae;
}
.Astronaut_Phone .pad .dial-pad .contact .contact-info .contact-number {
  color: white;
}
.Astronaut_Phone .pad .dial-pad .contact .contact-info .contact-number span {
  color: #3de066;
  display: inline;
}
.Astronaut_Phone .pad .dial-pad .contact .contact-buttons {
  position: absolute;
  right: -5px;
  top: 0px;
  width: 40px;
  height: 76px;
}
.Astronaut_Phone .pad .dial-pad .contact .contact-buttons button {
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  box-shadow: 0 12px 25px -5px black;
  display: block;
  position: absolute;
  right: 0px;
  background-size: 75% auto;
  background-position: center center;
  background-repeat: no-repeat;
}
.Astronaut_Phone .pad .dial-pad .contact .contact-buttons button:focus {
  outline: none;
}
.Astronaut_Phone .pad .dial-pad .contact .contact-buttons button.icon-message {
  background-color: #ffc44e;
  top: 5px;
}
.Astronaut_Phone .pad .dial-pad .contact .contact-buttons button.icon-video {
  background-color: #a529f9;
  bottom: 5px;
}
.Astronaut_Phone .pad .dial-pad .phoneString {
  width: 100%;
  height: 80px;
  background-color: #2d2d2d;
  margin-top: 40px;
}
.Astronaut_Phone .pad .dial-pad .phoneString input {
  background-color: transparent;
  width: 60%;
  margin-left: 20%;
  height: 80px;
  border: none;
  font-size: 30px;
  color: white;
  font-weight: 700;
  letter-spacing: 2px;
}
.Astronaut_Phone .pad .dial-pad .phoneString input:focus {
  outline: none;
}
.Astronaut_Phone .pad .dial-pad .digits {
  overflow: hidden;
  width: 60%;
  margin-left: 20%;
  margin-top: 20px;
}
.Astronaut_Phone .pad .dial-pad .digits .dig-spacer {
  width: 60px;
  margin: 10px calc(50% - 90px);
  float: left;
}
.Astronaut_Phone .pad .dial-pad .digits .dig {
  color: white;
  font-size: 30px;
  float: left;
  background-color: #2d2d2d;
  text-align: center;
  width: 60px;
  height: 56px;
  border-radius: 100%;
  margin: 10px 0px;
  padding-top: 4px;
  font-weight: 700;
  cursor: pointer;
}
.Astronaut_Phone .pad .dial-pad .digits .dig.clicked {
  animation: pulse-gray linear 0.5s 1;
}
.Astronaut_Phone .pad .dial-pad .digits .dig:nth-child(3n-1) {
  margin: 10px calc(50% - 90px);
}
.Astronaut_Phone .pad .dial-pad .digits .dig.astrisk {
  padding-top: 17px;
  height: 43px;
}
.Astronaut_Phone .pad .dial-pad .digits .dig.pound {
  padding-top: 0px;
  height: 50px;
}
.Astronaut_Phone .pad .dial-pad .digits .dig .sub-dig {
  font-size: 8px;
  font-weight: 300;
  position: relative;
  top: -2px;
}
.Astronaut_Phone .pad .dial-pad .digits .dig.addPerson,
.pad .dial-pad .digits .dig.goBack {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 55% auto;
  margin-bottom: 25px;
  box-shadow: 0px 25px 30px -15px black;
}
.Astronaut_Phone .pad .dial-pad .digits .dig.addPerson {
  background-color: #285efa;
  background-image: url(https://s16.postimg.org/4u2rbu85t/add_Person.png);
}
.Astronaut_Phone .pad .dial-pad .digits .dig.addPerson.clicked {
  animation: pulse-blue linear 0.5s 1;
}
.Astronaut_Phone .pad .dial-pad .digits .dig.goBack {
  background-color: #fa4a5d;
  background-image: url(https://s4.postimg.org/x6g6auu7d/back_Arrow.png);
}
.Astronaut_Phone .pad .dial-pad .digits .dig.goBack.clicked {
  animation: pulse-red linear 0.5s 1;
}
.Astronaut_Phone .pad .call-pad {
  opacity: 0;
  height: 0px;
  pointer-events: none;
  background-color: black;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  transition: opacity 0.3s ease;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  transition: opacity 0.3s ease;
}
.Astronaut_Phone .pad .call-pad.in-call {
  height: 100%;
  opacity: 1;
  pointer-events: all;
}
.Astronaut_Phone .pad .call-pad .pulsate {
  opacity: 0;
  width: 150px;
  height: 0px;
  overflow: visible;
  position: relative;
  display: block;
  margin: 0 auto 0;
  top: 120px;
  transition: opacity 0.5s ease;
}
.Astronaut_Phone .pad .call-pad .pulsate.active-call {
  animation: pulsator 2s ease infinite;
  opacity: 1;
}
.Astronaut_Phone .pad .call-pad .pulsate div {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 100%;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.Astronaut_Phone .pad .call-pad .pulsate div:nth-child(1) {
  width: 110px;
  height: 110px;
}
.Astronaut_Phone .pad .call-pad .pulsate div:nth-child(2) {
  width: 122px;
  height: 122px;
}
.Astronaut_Phone .pad .call-pad .pulsate div:nth-child(3) {
  width: 134px;
  height: 134px;
}
.Astronaut_Phone .pad .call-pad .ca-avatar {
  width: 100px;
  height: 100px;
  margin: 70px auto;
  margin-bottom: 30px;
  display: block;
  background-color: #111111;
  border-radius: 100%;
  box-shadow: 0px 20px 25px -10px rgba(0, 0, 0, 0.8);
  background-position: center center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  transition: opacity 1s ease, transform 1s ease;
  opacity: 0.5;
  transform: scale(0.5, 0.5);
}
.Astronaut_Phone .pad .call-pad .ca-avatar.in-call {
  transform: scale(1, 1);
  opacity: 1;
}
.Astronaut_Phone .pad .call-pad .ca-name,
.pad .call-pad .ca-number,
.pad .call-pad .ca-status {
  width: 60%;
  margin-left: 20%;
  color: white;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
}
.Astronaut_Phone .pad .call-pad .ca-name {
  font-size: 18px;
}
.Astronaut_Phone .pad .call-pad .ca-number {
  font-size: 28px;
  letter-spacing: 2px;
}
.Astronaut_Phone .pad .call-pad .ca-status {
  font-size: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.Astronaut_Phone .pad .call-pad .ca-status:after {
  content: attr(data-dots);
  position: absolute;
}
.Astronaut_Phone .pad .call-pad .ca-buttons {
  width: 70%;
  margin-left: 15%;
}
.Astronaut_Phone .pad .call-pad .ca-buttons .ca-b-single {
  float: left;
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  position: relative;
  margin-bottom: 40px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 55% auto;
}
.Astronaut_Phone .pad .call-pad .ca-buttons .ca-b-single:nth-child(3n-1) {
  margin-left: calc(100% - 230px);
  margin-right: calc(100% - 230px);
}
.Astronaut_Phone .pad .call-pad .ca-buttons .ca-b-single:after {
  content: attr(data-label);
  color: white;
  position: absolute;
  text-align: center;
  font-size: 10px;
  width: 100px;
  bottom: -20px;
  left: -18px;
  letter-spacing: 2px;
}

.Astronaut_Phone .call {
  color: white;
  font-size: 30px;
  text-align: center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin: 10px 0px;
  font-weight: 700;
  cursor: pointer;
  position: absolute;
  left: calc(50% - 30px);
  bottom: 25px;
  box-shadow: 0px 25px 30px -15px black;
  background-color: #3de066;
}
.Astronaut_Phone .call .call-icon {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}
.Astronaut_Phone .call .call-icon.in-call {
  -ms-transform: rotate(134deg);
  -webkit-transform: rotate(134deg);
  transform: rotate(134deg);
}
.Astronaut_Phone .call .call-change {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
}
.Astronaut_Phone .call .call-change span {
  width: 70px;
  height: 67px;
  display: block;
  background-color: #fa4a5d;
  position: relative;
  top: 70px;
  left: 70px;
  border-radius: 100%;
  transition: left 0.3s ease, top 0.3s ease;
}
.Astronaut_Phone .call .call-change.in-call span {
  top: -5px;
  left: -5px;
}
.Astronaut_Phone .call.clicked {
  animation: pulse-green linear 0.5s 1 forwards;
}

@keyframes pulse-gray {
  0% {
    box-shadow: inset 0 0 0px 30px #2d2d2d, inset 0 0 0px 30px white;
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  30% {
    box-shadow: inset 0 0 0px 10px #2d2d2d, inset 0 0 0px 30px white;
  }
  60% {
    box-shadow: inset 0 0 0px 0px #2d2d2d, inset 0 0 0px 0px white;
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes pulse-blue {
  0% {
    box-shadow: inset 0 0 0px 30px #285efa, inset 0 0 0px 30px white;
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  30% {
    box-shadow: inset 0 0 0px 10px #285efa, inset 0 0 0px 30px white;
  }
  60% {
    box-shadow: inset 0 0 0px 0px #285efa, inset 0 0 0px 0px white;
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes pulse-green {
  0% {
    box-shadow: inset 0 0 0px 30px #3de066, inset 0 0 0px 30px white;
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  30% {
    box-shadow: inset 0 0 0px 10px #3de066, inset 0 0 0px 30px white;
  }
  60% {
    box-shadow: inset 0 0 0px 0px #3de066, inset 0 0 0px 0px white;
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes pulse-red {
  0% {
    box-shadow: inset 0 0 0px 30px #fa4a5d, inset 0 0 0px 30px white;
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10% {
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  30% {
    box-shadow: inset 0 0 0px 10px #fa4a5d, inset 0 0 0px 30px white;
  }
  60% {
    box-shadow: inset 0 0 0px 0px #fa4a5d, inset 0 0 0px 0px white;
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes pulsator {
  0% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  40% {
    -ms-transform: scale(0.8, 0.8);
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes showPad {
  0% {
    top: 50px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}
.Astronaut_Phone p {
  position: fixed;
  bottom: 0px;
  left: 15px;
  color: white;
  font-family: Lato;
  font-weight: 300;
  overflow: hidden;
}
.Astronaut_Phone p a:link,
p a:visited {
  color: white;
}
.Astronaut_Phone p a:hover {
  opacity: 0.5;
}
.Astronaut_Phone p img {
  width: 20px;
  height: 20px;
  position: relative;
  top: 6px;
}
.Astronaut_Phone #timep {
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
  font-size: 40px;
}
