@import url("https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@700&display=swap");


.CHRISTMAS  {
    font-family: 'Mountains of Christmas', cursive;
    padding:0;
    margin:0;
    margin-top:0px;
    background: url("imgs/background.png") no-repeat center center fixed;
        -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
}
.CHRISTMAS  .hint{
    font-family: 'Mountains of Christmas', cursive;
    text-align: center;
    color:white;
    font-size:20px;
}
.CHRISTMAS  textarea{
    margin-bottom: 20px;
    font-size: 15px;
    color:black;
    font-family: sans-serif;
}
.CHRISTMAS .bottom{

     text-align: center;
    color:white;
    font-size:30px;
}
.CHRISTMAS #done{
    display: none;
     font-size: 20px;
    margin: 10px;
}
.CHRISTMAS .link{
     cursor: pointer;
}
.CHRISTMAS #back{
     color:white;
    font-size:30px;
    padding:15px;
    position: absolute;
    top:10;
    left:10;

}
