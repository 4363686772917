@import url("https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@700&display=swap");

.CHRISTMAS {
  height: 100%;
  margin: 0;
  background-image: url("imgs/background.png");
  -webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
}


.CHRISTMAS #top{
    position:absolute;
    top:0;
    width:100%;
}
.CHRISTMAS #bottom{
    position:absolute;
    bottom:0;
    width:100%;
}
.CHRISTMAS .gift{
    width: 150px;
}
.CHRISTMAS #gifts {
width: 770px;
height: 150px;
position: absolute;
top:0;
bottom: 0;
left: 0;
right: 0;
padding:0;
padding-top:100px;
margin: auto;
background-image:  url("imgs/text.png");
background-size: cover;
}
.CHRISTMAS .movinggift{
animation: shake 500ms infinite;
animation-timing-function: linear;
}

@keyframes shake {
0% { transform: rotate(4deg); }
50% { transform: rotate(-4deg); }
100% { transform: rotate(4deg); }
}
.CHRISTMAS #hint{
    position:absolute;
    width: 780px;
    height:480px;
    left:50%;
    top:50%;
    margin-top: -240px;
    margin-left: -390px;
    background-image: url("imgs/banner.png");
    background-size: contain;

}
.CHRISTMAS #answer{
     display:block;
    position:absolute;
    width: 780px;
    height:450px;
    left:50%;
    top:50%;
    margin-top: -240px;
    margin-left: -390px;
    background-image: url("imgs/banner2.png");
    background-size: contain;

}
.CHRISTMAS #hinttext{
    text-align: center;
    color: white;
    margin:175px;
    font-size: 30px;
    font-family: 'Mountains of Christmas', cursive;
}
.CHRISTMAS #fillin{
    font-family: 'Mountains of Christmas', cursive;
   text-align: center;
    font-size:30px;
    color:white;

    margin:200px;
    margin-top:150px;
}
.CHRISTMAS input{
    font-size:50;
    color: white;
     background: transparent;
    border: 5px solid white  ;
    border-radius: 8px;
    text-transform:uppercase;
}
.CHRISTMAS #back-button{
    font-family: 'Mountains of Christmas', cursive;
    position: absolute;
    cursor:pointer;
    top:70px;
    left:100px;
    width:250px;
    height: 50px;
    font-size:20px;
    color: white;
}
.CHRISTMAS #bannergift{
    width: 200px;
    animation: shake 500ms infinite;
animation-timing-function: linear;
}
.CHRISTMAS #wrong{
    margin: 0;
    font-size:15;

}
.CHRISTMAS #cross{
    position: absolute;
    top:0;
    left:0;
    width: 50px;
    z-index: 100;
}
