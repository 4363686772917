/* The Modal (background) */
.HEROsettings {
  font-size: 15px;
}
.HEROsettings #text {
  width: 60%;
  margin-left: 20%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
}
.HEROsettings .button {
  font-size: 15px;
  background-color: #ff8400;
  border-radius: 28px;
  border: 1px solid #ff9900;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 10px 15px;
  text-decoration: none;
}
.HEROsettings #map {
  height: 300px; /* The height is 400 pixels */
  width: 70%; /* The width is the width of the web page */
  margin-left: 15%;
}
.HEROsettings input[type="text"] {
  font-size: 16px;
}
/* Full-width input fields */
.Hero_Panel input[type="text"],
.Hero_Panel input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Center the image and position the close button */
.Hero_Panel .imgcontainer {
  height: 40px;
  text-align: center;
  margin: 10px 0 12px 0;
  position: relative;
}

.Hero_Panel img.avatar {
  width: 40%;
  border-radius: 50%;
}
.Hero_Panel #telephone:hover {
  opacity: 0.7;
}

.Hero_Panel .container {
  font-size: 15px;
  padding: 16px;
}

.Hero_Panel span.psw {
  float: right;
  padding-top: 16px;
}

.Hero_Panel .modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.Hero_Panel .modal-content {
  background-color: #fefefe;
  margin: 5% auto 5% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
}

/* The Close Button (x) */
.Hero_Panel .close {
  position: absolute;
  right: 25px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}

.Hero_Panel .close:hover,
.Hero_Panel .close:focus {
  color: red;
  cursor: pointer;
}

/* Add Zoom Animation */
.Hero_Panel .animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
}

@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
  .Hero_Panel .cancelbtn {
    width: 100%;
  }
}
.Hero_Panel label {
  font-size: 2em;
}
.Hero_Panel button {
  background-color: #06874c;
  color: white;
  padding: 10px 10px;
  margin: 4px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.Hero_Panel button:hover {
  opacity: 0.8;
}

.Hero_Panel input.knop {
  width: 30%;
  margin-left: 35%;
  margin-top: 30px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;

  font-size: 15px;
  background: #3366cc;
  color: #fff;
  border: 1px solid #06874c;
  border-radius: 10px;
  -moz-box-shadow: 6px 6px 5px #999;
  -webkit-box-shadow: 6px 6px 5px #999;
  box-shadow: 6px 6px 5px #999;
}
.Hero_Panel input.knop:hover {
  color: #fff;
  background: #000;
  border: 1px solid #fff;
}
.Hero_Panel #map {
  width: 70%;
  height: 100%;
  margin-left: 15%;
  background-color: grey;
}
.Hero_Panel h1 {
  text-align: center;
  font-size: 30px;
  color: white;
}

.Hero_Panel {
  height: 100%;
  width: 100%;
  background: url("imgs/secret_service.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.Hero_Panel input.knop {
  width: 30%;
  margin-left: 35%;
  margin-top: 30px;
  padding: 15px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  background: #666666;
  color: #fff;
  border: 1px solid #666666;
  border-radius: 10px;
}

/* The container */
.Hero_Panel .labelcontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.Hero_Panel .labelcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.Hero_Panel .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.Hero_Panel .labelcontainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.Hero_Panel .labelcontainer input:checked ~ .checkmark {
  background-color: #06874c;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.Hero_Panel .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.Hero_Panel .labelcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.Hero_Panel .labelcontainer .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.Hero_Panel .centerbuttons {
  text-align: center;
}

.Hero_Panel #foto_text {
  margin-top: 150px;
  font-size: 17px;
  width: 450px;
  margin-left: 25px;
  text-align: center;
}
