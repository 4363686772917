@import "https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap";

.Agents_Secret #time {
  font-family: "Orbitron", sans-serif;
  position: absolute;
  font-size: 70px;
  bottom: 0;
  left: 0;
  margin: 10px;
  color: brown;
}
.Agents_Secret {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-image: url("imgs/security.jpg");
  background-size: cover;
}

.Agents_Secret #bridge {
  display: block;
  background-image: url("imgs/hint.png");
  background-image: -webkit-image-set(
    url("imgs/hint.png") 1x,
    url("imgs/hint.png") 2x
  );
  background-size: cover;
  width: 100%;
  height: auto;
  cursor: crosshair;
  cursor: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/circular-cursor.png)
      53 53,
    crosshair;
}

.Agents_Secret #bridgeContainer {
  text-align: center;
  font-family: Avenir, sans-serif;
}
.Agents_Secret #bottom {
  position: absolute;
  bottom: 0;
  width: 50%;
  left: 25%;
  margin: 30px;
}
.Agents_Secret #center {
  margin: auto;
  display: block;
  width: 600px;
  height: 100%;
  padding-top: 80px;
}

.Agents_Secret a {
  color: inherit;
}
.Agents_Secret a:hover {
  color: #7f8ff4;
}

.Agents_Secret .container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.Agents_Secret .uppercase {
  text-transform: uppercase;
}

.Agents_Secret .btn {
  display: inline-block;
  background: transparent;
  color: inherit;
  font: inherit;
  border: 0;
  outline: 0;
  padding: 0;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  cursor: pointer;
}
.Agents_Secret .btn--primary {
  background: #7f8ff4;
  color: #fff;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 12px 36px;
}
.Agents_Secret .btn--primary:hover {
  background: #6c7ff2;
}
.Agents_Secret .btn--primary:active {
  background: #7f8ff4;
  box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.2);
}
.Agents_Secret .btn--inside {
  margin-left: -96px;
}

.Agents_Secret #codewoord {
  font-size: 10pt;
  width: 300px;
  padding: 20px;
}
.Agents_Secret #checkbutton {
  font-size: 10pt;
}
.Agents_Secret .form__field {
  width: 360px;
  background: #fff;
  color: #a3a3a3;
  font: inherit;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  outline: 0;
  padding: 22px 18px;
}
