@import url("https://fonts.googleapis.com/css2?family=Metal+Mania&display=swap");

.Astronaut_Engine {
  background: url("imgs/steel.png");
  background-repeat: no-repeat;
  background-size: auto;
}
.Astronaut_Engine #time {
  font-size: 40px;
  font-family: "Metal Mania";
  position: absolute;
  top: 20px;
  right: 20px;
}
.Astronaut_Engine .title {
  font-family: "Metal Mania";
  position: absolute;
  font-size: 50px;
  width: 200px;
  height: 100px;
  top: 50%;
  left: 50%;
  text-align: center;
  margin-left: -100px;
  margin-top: -50px;
}
.Astronaut_Engine .subtitle {
  font-family: "Metal Mania";
  font-size: 30px;
}
