@import url("https://fonts.googleapis.com/css2?family=Chango&display=swap");
.Hero_WOverview {
  width: 100%;
  height: 100%;
  font-size: 15px;
  background-image: url("imgs/network.jpeg");
  background-size: cover;
}
.Hero_WOverview h1 {
  color: black;
  font-size: 30px;
  text-align: center;
  padding-top: 5%;
  font-family: "Chango";
}
.Hero_WOverview .dossiers {
  width: 70%;
  height: 30%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.Hero_WOverview .dossier {
  width: 20%;
  padding: 1%;
  float: left;
}

.Hero_WOverview .dossier img:hover {
  opacity: 0.8;
}
.Hero_WOverview .dossier img {
  width: 100%;
}
.Hero_WOverview .dossier h2 {
  font-size: 17px;
  text-align: center;
}

.Hero_WOverview {
  font-family: Arial, Helvetica, sans-serif;
}

/* Full-width input fields */
.Hero_WOverview input[type="text"],
.Hero_WOverview input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Center the image and position the close button */
.Hero_WOverview .imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

.Hero_WOverview img.avatar {
  width: 40%;
  border-radius: 50%;
}

.Hero_WOverview .container {
  padding: 16px;
}

.Hero_WOverview span.psw {
  float: right;
  padding-top: 16px;
}

/* The Modal (background) */
.Hero_WOverview .modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  padding-top: 60px;
}

/* Modal Content/Box */
.Hero_WOverview .modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
}

/* The Close Button (x) */
.Hero_WOverview .close {
  position: absolute;
  right: 25px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}

.Hero_WOverview .close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}

/* Add Zoom Animation */
.Hero_WOverview .animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
}

@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
  .Hero_WOverview .cancelbtn {
    width: 100%;
  }
}
.Hero_WOverview label {
  font-size: 14px;
}
.Hero_WOverview button {
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  font-size: 15px;
  border: none;
  cursor: pointer;
  width: 100%;
}

.Hero_WOverview button:hover {
  opacity: 0.8;
}

.Hero_WOverview input.dader_knop {
  width: 30%;
  margin-left: 35%;
  margin-top: 30px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  background: #3366cc;
  color: #fff;
  border: 1px solid #3366cc;
  border-radius: 10px;
  -moz-box-shadow: 6px 6px 5px #999;
  -webkit-box-shadow: 6px 6px 5px #999;
  box-shadow: 6px 6px 5px #999;
}
.Hero_WOverview input.dader_knop:hover {
  background: #000;
  border: 1px solid #fff;
}
