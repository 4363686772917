.Hero_Chat {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 25px;
  font-style: normal;
}
.Hero_Chat .center {
  width: 600px;
  height: 300px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}
.Hero_Chat #chatbox {
  border: 2px solid #06874c;
  padding: 7px;
  border-radius: 18px;
  color: white;
  background-color: #06874c;
  width: 500px;
  overflow: auto;
}
.Hero_Chat .button {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  color: black;
  border-radius: 18px;
  background-color: white;
  border: 2px solid #06874c;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  display: block;
  float: right;
}
.Hero_Chat .button:hover {
  background-color: #06874c;
  color: white;
}
.Hero_Chat #buttons {
  display: none;
  height: 100px;
  margin-top: 50px;
}
.Hero_Chat .profilepic {
  width: 80px;
  display: block;
  float: left;
  margin-left: -100px;
}
.Hero_Chat .name {
  font-size: 30px;
  display: block;
  float: left;
  margin-top: -40px;
}
.Hero_Chat .cross {
  position: absolute;
  width: 50px;
  top: 10px;
  right: 10px;
}
.Hero_Chat .cross:hover {
  -webkit-transform: rotate(-10deg);
  -moz-transform: rotate(-10deg);
  -o-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}
.Hero_Chat .profilepicloader {
  width: 20%;
  margin-left: 40%;
  display: block;
}
.Hero_Chat .loader {
  width: 40%;
  margin-left: 30%;
  display: block;
}
