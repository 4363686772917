@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import "~antd/dist/antd.css";
/* The Modal (background) */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: 1000;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
.modal-header {
  text-align: center;
  color: white;
  display: block;
  background-color: #0ead81;
  width: 100%;
  font-size: 25px;
  font-weight: bolder;
  margin-bottom: 20px;
  padding: 10px 0;
  border-radius: 10px;
}
.modal-body {
  margin: 20px;
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 35px;
  font-weight: bold;
  padding-right: 20px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #f0730d;
  border-bottom: 2px solid #f0730d;
}

.ant-spin-dot-item {
  background-color: #f0730d;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.main_screen {
  font-family: "Montserrat", sans-serif;
}
#left {
  position: absolute;
  width: 35%;
  left: 0;
  top: 0;
}
#right {
  position: absolute;
  width: 35%;
  right: 0;
  top: 0;
}
.gameimg {
  width: 100%;
  height: auto;
  border-radius: 25px;
}
.main_screen .game {
  position: relative;
  width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  margin-bottom: 50px;
}
.main_screen .loading {
  text-align: center;
  font-size: 20px;
  color: #f0730d;
  background-color: #fffcf5;
  width: 400px;
  margin: auto;
  margin-top: 100px;
}
.main_screen .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.6;
  border-radius: 25px;
}
.main_screen .played {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.75;
  border-radius: 25px;
}
.main_screen .linkbutton {
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  color: white;
  z-index: 2;
  display: block;
  background-color: #f0730d;
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-decoration: none;
  border-radius: 25px;
}
.main_screen .startbutton {
  width: 300px;
}
.main_screen #start {
  top: 60px;
}
.main_screen #test {
  top: -60px;
}
.main_screen .centerbutton {
  top: 0;
}
.main_screen .container1 {
  opacity: 0;
}
.main_screen .container2 {
  opacity: 1;
}
.main_screen .container1:hover {
  opacity: 1;
}
.main_screen #games {
  margin-bottom: 100px;
}
.main_screen .playedp {
  width: 100%;
  border-radius: 0;
  padding-right: 0;
  padding-left: 0;
  top: 0;
  margin: auto;
}
.main_screen .info {
  font-size: 18px;
  position: absolute;
  color: white;
  width: 100%;
  margin-top: 55%;
  text-align: center;
}
.main_screen .left {
  margin-left: 100px;
}
.main_screen .name {
  position: absolute;
  color: white;
  margin: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #f0730d;
  border-radius: 25px;
  text-align: center;
  font-size: 30px;
}
.main_screen #logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  background-color: #fffcf5;
}
.main_screen .desc {
  text-align: center;
  font-size: 18px;
  background-color: #fffcf5;
}
.main_screen p a {
  color: #f0730d;
  font-style: oblique;
}
.main_screen .ACTIE {
  background-color: #fffcf5;
  width: 700px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.39);
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.39);
}
.main_screen #actietitle {
  text-align: center;
  color: white;
  display: block;
  background-color: #0ead81;
  width: 100%;
  font-size: 25px;
  font-weight: bolder;
  margin-bottom: 20px;
  padding: 10px 0;
  border-radius: 10px;
}
.main_screen .actiebutton {
  cursor: pointer;
  font-size: 15px;
  color: white;
  z-index: 2;
  background-color: #0ead81;
  width: 160px;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  border-radius: 15px;
  margin: 5px;
}
.main_screen .accent {
  color: #f0730d;
  font-weight: bold;
  font-size: 20px;
}
.main_screen .center {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.main_screen #invite {
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
}
.main_screen input[type="text"] {
  width: 220px;
  font-size: 14px;
  padding: 5px 10px;
  margin: 4px 0;
}
.main_screen .ACTIE img {
  position: absolute;
  width: 130px;
  margin-left: -80px;
  margin-top: -30px;
}
.main_screen #counter {
  color: #0ead81;
  font-size: 15px;
}
.main_screen .gift {
  color: #0ead81;
  font-size: 15px;
  text-align: center;
}
.main_screen h2 {
  margin: 5px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}
.start_screen {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
.start_screen .centerbuttons {
  margin-top: 15%;
  text-align: center;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.75);
  height: 270px;
  font-weight: 600;
}
.start_screen p {
  padding: 25px;
}
.start_screen .overlay {
  position: absolute;
  width: 150px;
  bottom: 0;
  right: 0;
  margin: 10px;
}
.start_screen .linkbutton {
  cursor: pointer;
  font-size: 20px;
  color: white;
  z-index: 2;
  display: block;
  background-color: #f0730d;
  width: 250px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: auto;
  text-decoration: none;
  border-radius: 25px;
}
.testmode {
  position: absolute;
  z-index: 100000;
  width: 250px;
  height: 220px;
  display: block;
  background-color: grey;

  color: white;
  font-size: 20px;
  text-align: center;
}
.testmode p {
  margin: 15px;
  font-weight: 400;
}
.testmode .info {
  margin: 0;
  font-size: 14px;
}
.testmode #answer {
  font-weight: 600;
  font-size: 18px;
  white-space: pre-line;
}
.testmode .header {
  height: 40px;
  background-color: #f0730d;
  font-weight: 600;
  color: white;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
}

p.accordion {
  color: #ef720c;
  cursor: pointer;
  padding: 18px;
  font-size: 15px;
  width: 100%;
  font-weight: bold;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  margin-bottom: 10px;
  border-bottom: 2px solid #ef720c;
}

/* Add a background color to the accordion if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
p.accordion.active,
p.accordion:hover {
  background-color: #ef720c;
  color: white;
}

/* Unicode character for "plus" sign (+) */
p.accordion:after {
  content: "\2795";
  color: #777;
  float: right;
  float: right;
  margin-left: 5px;
}

/* Unicode character for "minus" sign (-) */
p.accordion.active:after {
  content: "\2796";
}

/* Style the element that is used for the panel class */

div.panel {
  font-size: 15px;
  padding-top: 50px;
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: 0.4s ease-in-out;
  opacity: 0;
  margin-bottom: 10px;
}

div.panel.show {
  opacity: 1;
  max-height: 500px; /* Whatever you like, as long as its more than the height of the content (on all screen sizes) */
}
