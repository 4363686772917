@media screen and (max-width: 1000px) {
  #logout {
    display: none;
  }
}

.ant-dropdown-trigger{
color:white;
padding: 10px;
background-color: #f0730d;
&:hover {
  color:white;
}
}


.admin-background {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 221, 119, 1);
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
}

.admin-game {
  display: block;
  margin: auto;
  margin-top: 40px;
  color: #f0730d;
  background-color: rgba(255, 255, 255, 0.2);
  max-width: 400px;
  padding: 30px;
}

#root {
  height: 100%;
}

.logo {
  width: 80%;
  margin: 10%;
  margin-top: 10%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#error_message {
  color: #f0730d;
  font-size: 12px;
  visibility: hidden;
}
#help {
  color: white;
  font-size: 14px;
}
html,
body {
  font-size: 62.5%;
  height: 100%;
}
@media (max-width: 768px) {
  html,
  body {
    font-size: 50%;
  }
}

svg {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  overflow: visible;
}

.svg-icon {
  cursor: pointer;
}
.svg-icon path {
  stroke: rgba(255, 255, 255, 0.9);
  fill: none;
  stroke-width: 1;
}

input,
button {
  outline: none;
  border: none;
}

.cont {
  position: relative;
  height: 100%;
  background-size: cover;
  overflow: auto;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
.demo {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15rem;
  margin-top: -26.5rem;
  width: 30rem;
  height: 50rem;
  overflow: hidden;
}

.panel1 {
  position: absolute;
  top: 50%;
  left: 10%;
  margin-left: -15rem;
  margin-top: -26.5rem;
  width: 30rem;
  height: 45rem;
  overflow: hidden;
}

.panel2 {
  position: absolute;
  top: 50%;
  right: 10%;
  margin-left: -15rem;
  margin-top: -26.5rem;
  width: 30rem;
  height: 45rem;
  overflow: hidden;
}

.login {
  position: relative;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 221, 119, 1) 0%,
    rgba(255, 221, 119, 1) 100%
  );
  transition: opacity 0.1s,
    -webkit-transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25);
  transition: opacity 0.1s,
    transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25);
  transition: opacity 0.1s,
    transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25),
    -webkit-transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.login.inactive {
  opacity: 0;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.login__form {
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
  height: 65%;
  padding: 1.5rem 2.5rem;
  text-align: center;
}
.login__row {
  height: 5rem;
  padding-top: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.2);
}
.login__icon {
  margin-bottom: -0.4rem;
  margin-right: 0.5rem;
}
.login__icon.name path {
  stroke-dasharray: 73.50196075439453;
  stroke-dashoffset: 73.50196075439453;
  -webkit-animation: animatePath 2s 0.5s forwards;
  animation: animatePath 2s 0.5s forwards;
}
.login__icon.pass path {
  stroke-dasharray: 92.10662841796875;
  stroke-dashoffset: 92.10662841796875;
  -webkit-animation: animatePath 2s 0.5s forwards;
  animation: animatePath 2s 0.5s forwards;
}
.login__input {
  display: inline-block;
  width: 22rem;
  height: 100%;
  padding-left: 1.5rem;
  font-size: 1.5rem;
  font-weight: 400;
  background: transparent;
  color: #281745;
}
.login__submit {
  position: relative;
  width: 100%;
  height: 4rem;
  margin: 3rem 0 2.2rem;
  color: rgba(255, 255, 255, 0.8);
  background: #f0730d;
  font-size: 1.5rem;
  border-radius: 3rem;
  cursor: pointer;
  overflow: hidden;
  transition: width 0.3s 0.15s, font-size 0.1s 0.15s;
}
.login__submit:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1.5rem;
  margin-top: -1.5rem;
  width: 3rem;
  height: 3rem;
  border: 2px dotted #fff;
  border-radius: 50%;
  border-left: none;
  border-bottom: none;
  transition: opacity 0.1s 0.4s;
  opacity: 0;
}
.login__submit.processing {
  width: 4rem;
  font-size: 0;
}
.login__submit.processing:after {
  opacity: 1;
  -webkit-animation: rotate 0.5s 0.4s infinite linear;
  animation: rotate 0.5s 0.4s infinite linear;
}
.login__submit.success {
  transition: opacity 0.1s 0.3s, background-color 0.1s 0.3s,
    -webkit-transform 0.3s 0.1s ease-out;
  transition: transform 0.3s 0.1s ease-out, opacity 0.1s 0.3s,
    background-color 0.1s 0.3s;
  transition: transform 0.3s 0.1s ease-out, opacity 0.1s 0.3s,
    background-color 0.1s 0.3s, -webkit-transform 0.3s 0.1s ease-out;
  -webkit-transform: scale(30);
  transform: scale(30);
  opacity: 0.9;
}
.login__submit.success:after {
  transition: opacity 0.1s 0s;
  opacity: 0;
  -webkit-animation: none;
  animation: none;
}
.login__signup {
  font-size: 1.3rem;
  color: #281745;
}
.login__signup a {
  color: #0ead81;
  cursor: pointer;
}

.ripple {
  position: absolute;
  width: 15rem;
  height: 15rem;
  margin-left: -7.5rem;
  margin-top: -7.5rem;
  background: rgba(0, 0, 0, 0.4);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation: animRipple 0.4s;
  animation: animRipple 0.4s;
  border-radius: 50%;
}

@-webkit-keyframes animRipple {
  to {
    -webkit-transform: scale(3.5);
    transform: scale(3.5);
    opacity: 0;
  }
}

@keyframes animRipple {
  to {
    -webkit-transform: scale(3.5);
    transform: scale(3.5);
    opacity: 0;
  }
}
@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes animatePath {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes animatePath {
  to {
    stroke-dashoffset: 0;
  }
}
