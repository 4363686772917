.Astronaut_Movie {
  display: flex;
  flex-direction: column;
  height: 93vh;
  justify-content: center;
  align-items: center;
  background: #222;
  color: #eee;
  font-family: "Dosis", sans-serif;
}
.Astronaut_Movie .videoWrapper {
  width: 100%;
  height: 100%;
}

.Astronaut_Movie .underlined-a {
  text-decoration: none;
  color: aqua;
  padding-bottom: 0.15em;
  box-sizing: border-box;
  box-shadow: inset 0 -0.2em 0 aqua;
  transition: 0.2s;
  &:hover {
    color: #222;
    box-shadow: inset 0 -2em 0 aqua;
    transition: all 0.45s cubic-bezier(0.86, 0, 0.07, 1);
  }
}

.Astronaut_Movie .brk-btn {
  visibility: hidden;
  position: relative;
  background: none;
  color: aqua;
  text-transform: uppercase;
  text-decoration: none;
  border: 0.2em solid aqua;
  padding: 0.5em 1em;
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 10%;
    background: #222;
    height: 0.3em;
    right: 20%;
    top: -0.21em;
    transform: skewX(-45deg);
    -webkit-transition: all 0.45s cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 0.45s cubic-bezier(0.86, 0, 0.07, 1);
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 10%;
    background: #222;
    height: 0.3em;
    left: 20%;
    bottom: -0.25em;
    transform: skewX(45deg);
    -webkit-transition: all 0.45 cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 0.45s cubic-bezier(0.86, 0, 0.07, 1);
  }
  &:hover {
    &::before {
      right: 80%;
    }
    &::after {
      left: 80%;
    }
  }
}
.Astronaut_Movie #overlay {
  position: absolute;
  width: 100%;
  display: bock;
  z-index: 10;
  display: flex;
  flex-direction: column;
  height: 93vh;
  justify-content: center;
  align-items: center;
  background: #222;
  color: #eee;
  font-family: "Dosis", sans-serif;
}
.Astronaut_Movie #overlay2 {
  position: absolute;
  width: 100%;
  display: bock;
  z-index: 5;
  display: flex;
  flex-direction: column;
  height: 93vh;
  justify-content: center;
  align-items: center;
  background: #222;
  color: #eee;
  font-family: "Dosis", sans-serif;
}

.Astronaut_Movie p {
  position: absolute;
  font-size: 30px;
  color: white;
  top: 20px;
}
