.Labo_Wires {
  padding: 0;
  margin: 0;
  background: url("imgs/metal2.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

.Labo_Wires #time {
  font-family: "Skranji", cursive;
  color: white;
  font-size: 50px;
  text-align: center;
  margin: 10px;
  background-color: black;
}
.Labo_Wires #timecontainer {
  position: absolute;
  right: 180px;
  top: 0;
  width: -webkit-calc(50% - 180px);
  width: -moz-calc(50% - 180px);
  width: calc(50% - 180px);
  height: 130px;
}
.Labo_Wires #ledcontainer {
  padding-top: 20px;
  position: absolute;
  left: 180px;
  top: 0;
  width: -webkit-calc(50% - 180px);
  width: -moz-calc(50% - 180px);
  width: calc(50% - 180px);
  height: 130px;
  font-family: "Skranji", cursive;
  color: white;
  font-size: 20px;
  text-align: center;
}

.Labo_Wires .cable {
  margin-left: 180px;
  width: calc(100% - 360px);
}
.Labo_Wires .number {
  text-align: center;
  line-height: 30px;
  left: 200px;
  border-radius: 15px;
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: white;
  font-family: "Skranji", cursive;
  font-size: 20px;
  margin-top: 20px;
}

.Labo_Wires #cover {
  position: absolute;
  z-index: 10;
  background-color: black;
  width: 100%;
  height: 100%;
  background: url("imgs/metal.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.Labo_Wires #covercontainer {
  position: absolute;
  margin-left: 180px;
  margin-right: 180px;
  margin-top: 130px;
  width: -webkit-calc(100% - 360px);
  width: -moz-calc(100% - 360px);
  width: calc(100% - 360px);
  height: -webkit-calc(100% - 130px);
  height: -moz-calc(100% - 130px);
  height: calc(100% - 130px);
  overflow: hidden;
}
.Labo_Wires .screw {
  width: 45px;
  display: block;
  position: absolute;
  padding: 10px;
}
.Labo_Wires #screw1 {
  left: 0;
  top: 0;
}
.Labo_Wires #screw2 {
  top: 0;
  right: 0;
}
.Labo_Wires #screw3 {
  bottom: 0;
  left: 0;
}
.Labo_Wires #screw4 {
  bottom: 0;
  right: 0;
}

.Labo_Wires #cables {
  max-width: 100%;
  overflow: hidden;
}
.Labo_Wires #metal-left {
  position: absolute;
  left: 0;
  height: 100%;
  width: 180px;
  background-image: url("imgs/metal-left.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 10;
}
.Labo_Wires #metal-top {
  position: absolute;
  top: 0;
  height: 130px;
  width: 100%;
  background-image: url("imgs/metal-top.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.Labo_Wires #metal-right {
  position: absolute;
  right: 0;
  height: 100%;
  width: 180px;
  background-image: url("imgs/metal-right.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 10;
}
.Labo_Wires #buffer {
  height: 120px;
  width: 100%;
}
.Labo_Wires #led {
  display: block;
  float: left;
  width: 30px;
  margin-right: 20px;
}
.Labo_Wires #ledtext {
  float: left;
  background-color: black;
  padding: 5px;
}

.Labo_Wires #doors {
  display: inline-block;
  margin-top: 10px;
  height: 30px;
}
