.Astronaut_Maze {
  margin: 0;
  width: 100%;
  height: 100%;
}
.Astronaut_Maze p {
  color: whitesmoke;
  text-align: center;
  margin: 20px;
  font-size: 39px;
  font-family: "Orbitron", sans-serif;
}
.Astronaut_Maze #back {
  width: 100%;
  height: 100%;
}
.Astronaut_Maze #rocket {
  position: absolute;
  width: 11.12%;
  bottom: 0;
  right: 0;
}

.Astronaut_Maze #aarde {
  position: absolute;
  height: 13%;
  top: 1%;
  left: 1%;
}
.Astronaut_Maze #arrowu {
  position: absolute;
  width: 11.12%;
  height: 16.67%;
  bottom: 16.67%;
  right: 44.44444%;
}

.Astronaut_Maze #arrowd {
  position: absolute;
  width: 11.12%;
  height: 16.67%;
  bottom: 0;
  right: 44.4444%;
}
.Astronaut_Maze #arrowr {
  position: absolute;
  width: 11.12%;
  height: 16.67%;
  bottom: 0%;
  right: 33.3333%;
}
.Astronaut_Maze #arrowl {
  position: absolute;
  width: 11.12%;
  height: 16.67%;
  bottom: 0%;
  right: 55.5555%;
}
.Astronaut_Maze #timefixed {
  position: absolute;
  width: 22.22%;
  height: 16.67%;
  bottom: 58.33%;
  margin-bottom: -4%;
  left: 0%;
}
.Astronaut_Maze p {
  font-size: 30px;
  line-height: 20px;
  color: #eee;
  font-family: "Dosis", sans-serif;
}
