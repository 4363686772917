.Hero_Welcome .cont {
  background: url("imgs/secret_service.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.Hero_Welcome .demo {
  background-color: black;
  position: fixed;
  display: block;
  width: 40%;
  height: 300px;
  padding: 50px;
  margin-left: -20%;
  margin-right: auto;
}
.Hero_Welcome .login__submit {
  background: #556677;
}
.Hero_Welcome .login__form {
  position: absolute;
  top: 0%;
}
.loading {
  width: 50%;
  margin-left: 25%;
}
h1 {
  text-align: center;
  color: white;
  font-size: 15px;
}

.Hero_Welcome {
  font-family: Arial, Helvetica, sans-serif;
}

/* Full-width input fields */
.Hero_Welcome input[type="text"],
.Hero_Welcome input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Center the image and position the close button */
.Hero_Welcome .imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

.Hero_Welcome img.avatar {
  width: 40%;
  border-radius: 50%;
}

.Hero_Welcome .container {
  padding: 16px;
}

.Hero_Welcome span.psw {
  float: right;
  padding-top: 16px;
}

/* The Modal (background) */
.Hero_Welcome .modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  padding-top: 60px;
}

/* Modal Content/Box */
.Hero_Welcome .modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
}

/* The Close Button (x) */
.Hero_Welcome .close {
  position: absolute;
  right: 25px;
  top: 0;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}

.Hero_Welcome .close:hover,
.Hero_Welcome .close:focus {
  color: red;
  cursor: pointer;
}

/* Add Zoom Animation */
.Hero_Welcome .animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
}

@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
  .cancelbtn {
    width: 100%;
  }
}
.Hero_Welcome label {
  font-size: 2em;
}
.Hero_Welcome button {
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  font-size: 15px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

.Hero_Welcome button:hover {
  opacity: 0.8;
}
.Hero_Welcome p {
  font-size: 15px;
}
